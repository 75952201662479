import React, { Fragment } from 'react'
import classNames from 'classnames'

import Layout from '../components/layout'
import Img from '../components/img'
import FeatureHeadingSection from '../components/feature-heading-section'
import StripSection from '../components/strip-section'
import SetupInstructions from '../components/setup-instructions'
import SetupIllustration from '../components/setup-illustration'

import ciVisibilityIcon from '../images/features/ci-visibility-icon.svg'
import decorationStrip from '../images/ci-visibility/decoration-strip.svg'
import decorationBlob from '../images/ci-visibility/decoration-blob.svg'
import dot from '../images/ci-visibility/dot.svg'

const DetailsIllustration = ({ imgId, highlights, first }) => (
  <div className={classNames('illustration-wrapper', { 'full-width': first })}>
    <img
      className="decoration"
      src={first ? decorationStrip : decorationBlob}
      alt=""
    />
    <div className="illustration-content">
      <div className="illustration">
        <div className="img-wrapper">
          {highlights.map(({ top, left, text }, index) => (
            <Fragment key={index}>
              <img
                className="dot"
                style={{ top: `${top}%`, left: `${left}%` }}
                src={dot}
                alt=""
              />
              <p
                className="tooltip"
                style={{ top: `${top}%`, left: `${left}%` }}
              >
                {text}
              </p>
            </Fragment>
          ))}
          <Img id={imgId} />
        </div>
      </div>
    </div>
  </div>
)

const DetailsSection = ({ title, children }) => (
  <section className="full-width centered details">
    <h2>{title}</h2>
    {children}
  </section>
)

const DetailsItem = ({ title, children: [description, image] }) => (
  <div className="item full-width">
    <div className="description">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>

    {image}
  </div>
)

const CiVisibilityPage = () => {
  return (
    <Layout className="ci-visibility">
      <FeatureHeadingSection iconSrc={ciVisibilityIcon}>
        CI Visibility
      </FeatureHeadingSection>

      <section className="feature">
        <div className="description">
          <h2>Test Aggregation</h2>

          <p>
            Test runs and the test status is aggregated at the commit level.
          </p>
          <p>
            This allows us to point out when tests exhibit flakiness and lets
            you analyze every test run that occurred against a given commit.
          </p>
        </div>

        <div className="illustration">
          <Img id="ciVisibilityTestAggregation" />
        </div>
      </section>

      <DetailsSection title="Fine-Grained Details of Your Entire System">
        <DetailsItem title="Distributed Tracing">
          <>
            Production-level observability gives you fine-grained details to{' '}
            <strong>
              know the exact behavior of the entire system as your tests are
              executed
            </strong>
            , whether it's a unit test or an integration test across dozens of
            external services.
          </>

          <DetailsIllustration
            imgId="ciVisibilityDistributedTracing"
            highlights={[
              {
                top: 31,
                left: 78,
                text:
                  'Know exactly what your test is doing and identify the exact service and request responsible for test failure or increased latency.',
              },
              {
                top: 78,
                left: 52,
                text: 'We bring production-level visibility into every test.',
              },
            ]}
            first
          />
        </DetailsItem>

        <DetailsItem title="Logs & Events">
          <>
            View only the structured logs and events output for the specific
            test run you're investigating, ordered by time. Be as verbose with
            your log output as you want and run your tests in parallel without
            having to worry about exporting your CI logs to another tool just to
            make sense of them.
          </>

          <DetailsIllustration
            imgId="ciVisibilityLogsEvents"
            highlights={[
              {
                top: 12,
                left: 55,
                text: "Use filters to find the exact logs you're looking for.",
              },
              {
                top: 62,
                left: 20,
                text:
                  'Logs and events from every service involved in the test transaction are captured and sorted by time.',
              },
            ]}
          />
        </DetailsItem>

        <DetailsItem title="Exceptions">
          <>
            <strong>Every error is captured and contextualized</strong>, whether
            its thrown by your service or a dependency. See relevant metadata,
            the source code that threw the error, the symbolicated stacktrace,
            and more.
          </>

          <DetailsIllustration
            imgId="ciVisibilityExceptions"
            highlights={[
              {
                top: 72,
                left: 52,
                text:
                  'Gain immediate context with source code shown from right within the stacktrace.',
              },
            ]}
          />
        </DetailsItem>

        <DetailsItem title="Code Path">
          <>
            <strong>
              Scope captures per-test code coverage for each individual test run
            </strong>
            . This allows comparison between two different test runs, to
            identify differences in the code executed. Code Path is also
            leveraged by our Intelligent Test Runner to identify whether the
            test has been impacted by a given commit.
          </>

          <DetailsIllustration
            imgId="ciVisibilityCodePath"
            highlights={[
              {
                top: 50,
                left: 52,
                text:
                  'View the exact files and lines of code each test run traversed, along with the number of executions.',
              },
            ]}
          />
        </DetailsItem>
      </DetailsSection>

      <DetailsSection title="Uncover Insights Using Historical Data for Every Test">
        <DetailsItem title="Test Code">
          <>
            Quick access to the test code makes for easy referencing without
            having to dig through source code.
          </>

          <DetailsIllustration
            imgId="ciVisibilityTestCode"
            highlights={[
              {
                top: 31,
                left: 30,
                text:
                  'You have quick access to the test code without needing to search for it.',
              },
            ]}
            first
          />
        </DetailsItem>

        <DetailsItem title="History">
          <>
            See at a glance every commit a test was run against along with the
            test status. Debugging is easier when you're a single click away
            from viewing the commit diff between any two commits in which the
            test ran.
          </>

          <DetailsIllustration
            imgId="ciVisibilityHistory"
            highlights={[
              {
                top: 43,
                left: 40,
                text:
                  "See every commit that has run the test you're investigating.",
              },
              {
                top: 46,
                left: 75,
                text:
                  'Want to know what code changes were introduced between a passing and failing test? Click compare to view the git diff between the two commits.',
              },
            ]}
          />
        </DetailsItem>

        <DetailsItem title="Performance">
          <>
            Identify trends and spot outliers in test performance to reduce the
            number of performance regressions landing in production. Scope also
            supports benchmark tests.
          </>

          <DetailsIllustration
            imgId="ciVisibilityPerformance"
            highlights={[
              {
                top: 42,
                left: 76,
                text:
                  'Quickly spot the commit that introduced a performance regression.',
              },
            ]}
          />
        </DetailsItem>
      </DetailsSection>

      <StripSection className="setup">
        <div className="description">
          <SetupIllustration />
          <h2>
            Everything You Need to Understand and Debug Any Test in 5 Minutes
          </h2>
          <p className="note">
            Simple to include libraries remove the need for manual
            instrumentation{' '}
            <strong>(.NET, Java, Node.js, Javascript, iOS)</strong> or make it
            much easier <strong>(Go)</strong>.
          </p>
        </div>

        <SetupInstructions />
      </StripSection>
    </Layout>
  )
}

export default CiVisibilityPage
